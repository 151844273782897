import { RColumn, Space, Title2 } from "./otherCOmps";

import { ColorPalette } from "../util/const";

export function LoadingComp(){
    return(
        <Title2>HAT</Title2>
    )
}

export function LoadingModal({text = "Carico..."}){
    return(
        <div style={{zIndex: 10,height: "100vh", width: "100%", display: "flex", position: "fixed", backgroundColor: "rgba(0,0,0,0.5)", alignItems: "center", justifyContent: "center"}}>
            <div style={{borderRadius: "12px", width: "100%", maxWidth: "300px", height: "200px", backgroundColor: ColorPalette.bgColorDark, display: "flex", flexDirection: "column",alignItems: "center", justifyContent: "center"}}>
                <Title2>{text}</Title2>
                <Space/>
                <LoadingComp/>
            </div>
        </div>
    )
}