
export class ColorPalette{
    static primaryColor = "#967BA8";
    static accentOrange = "#D35F2F";
    static accentGreen = "#77B06D";

    static bgColorDark = "#0D0D0D";
    static containerColorDark = "#1A1A1A";
    static containerSecondaryColorDark = "#292929";
    static textColorDark = "white";
    static textSecondaryDark = "#CBCACA";

    static bgColorLight = "#F4F4F4";
    static containerColorLight = "#E3E3E3";
    static containerSecondaryColorLight = "#F4F4F4";
    static textColorLight = "#000000";
    static textSecondaryLight = "#121212";
}