import { InputField, PrimaryBtn, RColumn, RGrid, RPadding, RRow, RText, SecondaryBtn, Space, SpaceHorizontal, SubTitle1, Title1, Title2 } from "../comps/otherCOmps"
import { ColorPalette } from "../util/const";
import { useEffect, useState } from 'react';
import { auth, createAccount, db, functions, login } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { showErrorNotification, showSuccessNotification } from '../comps/errorMessagesComp';
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import SingleMentor from "../comps/singleMentor";
import RifiutaPage from "./rifiutaPage";
import { httpsCallable } from "firebase/functions";
import { LoadingModal } from "../comps/loadinComp";

const HomePage = () => {
  const [emailField, setEmailField] = useState('');  
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const isMobile = false;
  const [isRifiuta, setIsRifiuta] = useState(false);
  const [isApprova, setIsApprova] = useState(false);
  const [isAbilitaModifica, setIsAbilitaModifica] = useState(false);
  const [approvRifData, setApprovRifData] = useState({uid: undefined, nome: undefined});
  const [isLoaing, setIsLoading] = useState(false);

  const [mentorDaApprovare, setMentorDaApporvare] = useState([]);

  const [viewMode, setiViewMode] = useState(0);

  useEffect(() => {
    dowloadNewMentors(0);
  },[]);

  useEffect(() => {
    dowloadNewMentors(viewMode);
  },[viewMode]);

  async function dowloadNewMentors(viewMod){
    const mentorRef = collection(db, "mentors");

    let q = query(mentorRef,
        where("approvato", "==", false),
        where("isSubmitted", "==", true),
        limit(30)
    );
    if(viewMod === 1){
       q = query(mentorRef,
        where("approvato", "==", false),
        where("isSubmitted", "==", false),
        limit(30)
    );
    }
    const querySnapshot = await getDocs(q);
    setMentorDaApporvare(querySnapshot.docs);
  }


  if(isRifiuta){
    return(
      <RifiutaPage nome={approvRifData.nome} uid={approvRifData.uid} onCancel={() => setIsRifiuta(false)}/>
    )
  }

  function onRifiuta(uid, nome){
    setApprovRifData({uid: uid, nome: nome});
    setIsRifiuta(true);
  }
  function onAccetta(uid, nome){
    setApprovRifData({uid: uid, nome: nome});
    setIsApprova(true);
  }
  function onAbilitaModifica(uid, nome){
    setApprovRifData({uid: uid, nome: nome});
    setIsAbilitaModifica(true);
  }

  async function approvaMentor(){
      setIsLoading(true);
      try{
      const funct = httpsCallable(functions, "approvaMentor");
          const dat = {
              uidMentor: approvRifData.uid,
          }
          await funct(dat);
          showSuccessNotification("Accettato");
          setIsApprova(false);
          dowloadNewMentors();
      }catch(e){
          showErrorNotification("Errore " + e);
      }
      setIsLoading(false);
  }
  async function abilitaModificheMentor(){
    setIsLoading(true);
    try{
    const funct = httpsCallable(functions, "abilitaModificheMentor");
        const dat = {
            uidMentor: approvRifData.uid,
        }
        await funct(dat);
        showSuccessNotification("Abilitato modifica");
        setIsApprova(false);
        dowloadNewMentors();
    }catch(e){
        showErrorNotification("Errore " + e);
    }
    setIsLoading(false);
}
  function PopUpAccetta(){
    return(
      <div style={{position: "fixed", width: "100vw", height: "100vh", backgroundColor: "rgba(0,0,0,0.6)"}}>
      <RColumn width={"100%"} height={"90vh"} horizontalAlign="center" verticalAlignment="center">
      <div style={{ borderRadius: "14px",width: isMobile ? "auto" : "400px",padding:"16px 20px", backgroundColor:ColorPalette.containerColorDark}}>
      <RColumn>
          <Title2 align={"center"}>Accettare {approvRifData.nome}?</Title2>
          <Space/>
          <RRow width={"100%"} horizontalAlign="center">
            <SecondaryBtn onClick={() => setIsApprova(false)}>Annulla</SecondaryBtn>
            <SpaceHorizontal/>
            <PrimaryBtn onClick={approvaMentor}>Si accetta</PrimaryBtn>
          </RRow>
      </RColumn>
   
      </div>
      </RColumn>
      </div>
    )
  }
  function PopUpAbilitaMod(){
    return(
      <div style={{position: "fixed", width: "100vw", height: "100vh", backgroundColor: "rgba(0,0,0,0.6)"}}>
      <RColumn width={"100%"} height={"90vh"} horizontalAlign="center" verticalAlignment="center">
      <div style={{ borderRadius: "14px",width: isMobile ? "auto" : "400px",padding:"16px 20px", backgroundColor:ColorPalette.containerColorDark}}>
      <RColumn>
          <Title2 align={"center"}>Abilitare modifche per {approvRifData.nome}?</Title2>
          <Space/>
          <RRow width={"100%"} horizontalAlign="center">
            <SecondaryBtn onClick={() => setIsAbilitaModifica(false)}>Annulla</SecondaryBtn>
            <SpaceHorizontal/>
            <PrimaryBtn onClick={abilitaModificheMentor}>Si abilita</PrimaryBtn>
          </RRow>
      </RColumn>
   
      </div>
      </RColumn>
      </div>
    )
  }
  return(
    <>
        {isLoaing && <LoadingModal/>}
        {isApprova && <PopUpAccetta/>}
        {isAbilitaModifica && <PopUpAbilitaMod/>}
        <Space height={isMobile ? "100px" : "200px"}/>
        <div style={{padding: isMobile ? "0px 22px" : "0px 20px"}}>
        <RColumn horizontalAlign="start" width={"100%"}>
          <RRow>
            <div onClick={() => setiViewMode(0)} style={{cursor:"pointer",padding: "8px", border: viewMode === 0 ? "2px solid " + ColorPalette.accentOrange : "2px solid transparent", borderRadius: "32px"}}><RText>da approvare</RText></div>
            <div onClick={() => setiViewMode(1)} style={{cursor:"pointer",padding: "8px", border: viewMode === 1 ? "2px solid " + ColorPalette.accentOrange : "2px solid transparent", borderRadius: "32px"}}><RText>in compilazione</RText></div>
          </RRow>
          <Space/>
          <RColumn width={"100%"}> 
              {mentorDaApprovare.map((mentor, ind) => (
                <SingleMentor viewMode={viewMode} data={mentor.data()} id={mentor.id} key={ind} onReload={() => dowloadNewMentors()} onAccetta={() => onAccetta(mentor.id, mentor.data().nome)} onRifuta={() => onRifiuta(mentor.id, mentor.data().nome)} onAbilitaModifica={() => onAbilitaModifica(mentor.id, mentor.data().nome)}/>
              ))}
          </RColumn>
        </RColumn>
        </div>
        <Space/>
      </>
    );
}
export default HomePage;