import { useState } from "react";
import { InputField, PrimaryBtn, RColumn, RText, Space, Title1, Title2 } from "../comps/otherCOmps"
import { ColorPalette } from "../util/const";
import { auth, db, logOut, login, verifyAccount } from "../firebase";
import { showErrorNotification } from "../comps/errorMessagesComp";
import { doc, getDoc } from "firebase/firestore";

const LoginPage = () => {
    const [emailField, setEmailField] = useState('');

    const isMobile = false;
  const emailInputChange = (event) => {
    setEmailField(event);
  };

  const [passwordield, setPasswordField] = useState('');

  const passwordInputChange = (event) => {
    setPasswordField(event);
  };

  const accedi = async () => {
    try{
        await login(emailField, passwordield);
        const adminRef = doc(db, "admin", auth.currentUser.uid);
        const adminDoc = await getDoc(adminRef);
        if(!adminDoc.exists()){
          showErrorNotification("Non sei admin!");
          await logOut();
          return;
        }
    }catch(e){
        showErrorNotification("Wrong email or password");
        await logOut();
        console.log(e);
    }
}

return(
        <>
        <RColumn width={"100%"} height={"90vh"} horizontalAlign="center" verticalAlignment="center">
        <div style={{ borderRadius: "14px",width: isMobile ? "auto" : "400px",padding:"16px 20px", backgroundColor:ColorPalette.containerColorDark}}>
        <RColumn>
            <Title2 align={"center"}>Log in</Title2>
            <Space/>
            <RText>Login con credenziali HAT admin</RText>
            <Space/>
             <InputField placeHolder={"Email"} value={emailField} setValue={emailInputChange} autoComplete={"email"}/>
            <InputField isPassword={true} placeHolder={"Password"} value={passwordield} setValue={passwordInputChange}/>
            <Space/>
            <PrimaryBtn onClick={accedi}>Log in</PrimaryBtn>
        </RColumn>
     
        </div>
        </RColumn>
        </>
    )
}
export default LoginPage;