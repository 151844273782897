import { httpsCallable } from "firebase/functions";
import { InputField, InputFieldMultiline, PrimaryBtn, RColumn, RRow, RText, SecondaryBtn, Space, SpaceHorizontal, Title2 } from "../comps/otherCOmps";
import { useState } from "react";
import { functions } from "../firebase";
import { showErrorNotification, showSuccessNotification } from "../comps/errorMessagesComp";
import { LoadingComp, LoadingModal } from "../comps/loadinComp";

export default function RifiutaPage({uid, nome, onCancel}){


    const [messaggio, setMessaggio] = useState("");

    const [isLoaing, setIsLoading] = useState(false);

    async function rifiuta(){
        setIsLoading(true);
        try{
        const funct = httpsCallable(functions, "rifiutaMentor");
            const dat = {
                uidMentor: uid,
                messaggio: messaggio,
            }
            await funct(dat);
            showSuccessNotification("Rifiutato");
            onCancel();
        }catch(e){
            showErrorNotification("Errore " + e);
        }
        setIsLoading(false);
    }
    return(
        <RColumn>
              {isLoaing && <LoadingModal/>}
                <div style={{width: "90%", maxWidth: "600px"}}>
                <Space height="130px"/>
                <Title2>Rifiuta {nome}</Title2>
                <Space/>
                <RText>Spiega perchè</RText>
                <Space/>
                <InputFieldMultiline value={messaggio} setValue={setMessaggio} placeHolder={"Moivo"}/>
                <Space/>
                <RRow width={"100%"} horizontalAlign="center">
                    <SecondaryBtn onClick={onCancel}>Annulla</SecondaryBtn>
                    <SpaceHorizontal/>
                    <PrimaryBtn onClick={rifiuta}>Invia rifiuto</PrimaryBtn>
                </RRow>
                
                </div>
        </RColumn>
    )
}