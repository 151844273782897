import './App.css';
import { ColorPalette } from './util/const';
import { BrowserRouter } from 'react-router-dom';
import StartingPage from './startingPage';

function App() {
  return (
    <div className="App" style={{backgroundColor: ColorPalette.bgColorDark, minHeight: "100vh"}}>
    <BrowserRouter>
      <StartingPage/>
    </BrowserRouter>
    </div>
  );
}

export default App;
