import { useState } from "react";
import { ColorPalette } from "../util/const";
import { InputField, InputFieldMultiline, PrimaryBtn, RColumn, RRow, RText, SecondaryBtn, Space, SpaceHorizontal, Title2 } from "./otherCOmps";
import modImg from "../assetts/pencil.png";
import saveImg from "../assetts/save.png";
import closeImg from "../assetts/close.png";

import { showErrorNotification, showSuccessNotification } from "./errorMessagesComp";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export default function SingleMentor({data, id, onRifuta, onAccetta, onAbilitaModifica, viewMode, onReload}){


    function SingleCampo({tit, children, canMod = false, modLine = 1, modCampo = null}){

        const [isModifying, setIsModifying] = useState(false);
        const [newVal, setNewVal] = useState(canMod ? data[modCampo] : "");

        if(viewMode !== 0) canMod = false;

        async function saveNewVal(){
            if(!modCampo){
                showErrorNotification("No campo!");
                return;
            }
            const mentorDocRef = doc(db,"mentors/" + id);
            const newData = {[modCampo]: newVal};
            try{
            await updateDoc(mentorDocRef, newData);
            }catch(e){
                showErrorNotification("Errore nel salvare: " + e);
                annullaNewVal();
                return;
            }
            setIsModifying(false);
            showSuccessNotification("Modifiche salvate");
            onReload();
        }
        function annullaNewVal(){
            setIsModifying(false);
            setNewVal(data[modCampo]);
        }
        return (
            <RRow padding={"4px 0px"} horizontalAlign="start" width={"100%"}>
                {canMod && !isModifying && <div style={{padding: "4px", cursor: "pointer"}} onClick={() => setIsModifying(true)} ><img src={modImg} width={"16px"}/></div>}
                {canMod && isModifying && <div style={{padding: "4px", cursor: "pointer"}} onClick={saveNewVal} ><img src={saveImg} width={"16px"}/></div>}
                {canMod && isModifying && <div style={{padding: "4px", cursor: "pointer"}} onClick={annullaNewVal} ><img src={closeImg} width={"16px"}/></div>}
                <SpaceHorizontal/>
                <RText>{tit}</RText>
                <SpaceHorizontal/>
                {!isModifying && <RText fontWeight="bold">{children}</RText>}
                {isModifying && <InputFieldMultiline numRighe={modLine} value={newVal} setValue={setNewVal}/>}
                
            </RRow>
        )
    }


    return(
        <div style={{margin: "20px 0px",width: "90%",border: "2px solid " + ColorPalette.primaryColor, padding: "20px", borderRadius: "12px"}}>
        <RColumn width={"100%"} horizontalAlign="start">
            <Space/>
            <RRow>
                <img src={data.profilePicLink} width={"100px"}/>
                <SpaceHorizontal/>
                <img src={data.copertinaPicLink} height={"100px"}/>
            </RRow>
            <Space/>
            <RRow>
                <SingleCampo canMod modCampo={"nome"}>{data.nome}</SingleCampo>
                <SpaceHorizontal/>
                <SingleCampo canMod modCampo={"cognome"}>{data.cognome}</SingleCampo>
            </RRow>
            <Space/>
            <SingleCampo tit={"Telefono"}>{data.telefono}</SingleCampo>
            <Space/>
            {data.cvLink && <SecondaryBtn onClick={() => window.open(data.cvLink, '_blank')}>Scarica CV</SecondaryBtn>}
            <Space/>
            <SingleCampo tit={"Nascita"}>{data.annoNascita}</SingleCampo>
            <SingleCampo tit={"Anni Esperienza"}>{data.anni_esperienza}</SingleCampo>
            <SingleCampo tit={"Categoria"}>{data.categoria}</SingleCampo>
            <SingleCampo tit={"Sotto categorie"}>{data.sotto_categoria?.map((cat, ind) => (<div key={ind} style={{marginRight: "10px"}}>{cat}</div>))}</SingleCampo>
            <SingleCampo tit={"descrizione"} canMod modCampo={"desc"} modLine={3}>{data.desc}</SingleCampo>
            <SingleCampo tit={"Città"}>{data.city}</SingleCampo>
            <SingleCampo canMod modCampo={"linkSocial"} tit={"Link Social"}><SecondaryBtn onClick={() => window.open(data.linkSocial, '_blank')}>Apri</SecondaryBtn></SingleCampo>
            <SingleCampo tit={"Aziende"}>{data.azienda?.map((cat, ind) => (<div key={ind} style={{marginRight: "10px"}}>{cat}</div>))}</SingleCampo>
            <SingleCampo tit={"Disponibilità"}>
            <RColumn>
                {data.disp && Object.entries(data.disp).map(([key, value]) => (
                <RRow key={key} padding={"3px 0px"} verticalAlignment="start">
                    <div style={{width: "55px"}}>
                        <RText fontWeight="bold" size="17px">{value.giorno.slice(0,3)}</RText>
                    </div>
                    <RColumn horizontalAlign="start">
                        {value.orari.map((o, index) => (
                            <RText key={index} size="15px">{o.start} - {o.end}</RText>
                        ))}
                    </RColumn>
                </RRow>
            ))}
            </RColumn>
            </SingleCampo>
        </RColumn>
       {viewMode === 0 && <RRow width={"100%"} horizontalAlign="center">
            <div onClick={onAbilitaModifica} style={{cursor: "pointer"}}><RText>Abilita modifica</RText></div>
            <SpaceHorizontal/>
            <SecondaryBtn onClick={onRifuta}>Rifiuta</SecondaryBtn>
            <SpaceHorizontal/>
            <PrimaryBtn onClick={onAccetta}>Approva</PrimaryBtn>
        </RRow>}
       
      </div>
    )
}